<template>
  <div class="home">
    <div class="home-left">
      <div v-if="rescueInfo" class="sos">
        <Icon style="font-size: 26px; color: red" type="ios-flash" />
        <div class="text">紧急救援</div>
        <div class="scool">
          <div
            class="blink"
            @click="
              () => {
                $router.push({
                  path: '/monitor/single',
                  query: {
                    eventid: rescueInfo.event_id,
                    villageId: rescueInfo.v_village_id,
                    registerNum: rescueInfo.register_number,
                    elevatorId: rescueInfo.v_elevator_id,
                    elevatorCode: rescueInfo.v_elevator_code,
                  },
                })
              }
            "
          >
            {{ rescueInfo.v_elevator_name }}
          </div>
          <!-- <ul>
            <li  v-for="(item, index) in rescueInfo"
            :key="index"
            class="blink" @click="() => {
                  $router.push({ path: '/monitor/single', query: { villageId: item.villageId,elevatorId:item.elevatorId,elevatorCode:item.elevatorCode} })
                }"> 
              {{ index + 1 + '.' }} {{ item.time }} {{ item.address }}
              {{ item.name + ' ' }}
            </li>
          </ul> -->
        </div>
      </div>
      <div class="table">
        <div class="fault">
          <div class="tips">
            <div class="round">
              <!-- <div class="icon">
                <Icon style="font-size:40px" type="md-eye" />
              </div> -->
              <div class="text">
                <div>故障</div>
                <div>提示</div>
              </div>
            </div>
          </div>
          <div class="list">
            <el-table
              ref="singleTable"
              :data="elevatorHlsInfoFault"
              highlight-current-row
              style="width: 100%"
              height="250"
            >
              <el-table-column
                property="dt_report_time"
                label="上报日期"
                align="center"
              >
              </el-table-column>
              <el-table-column
                property="v_fault_name"
                label="故障类型"
                align="center"
              >
              </el-table-column>
              <el-table-column
                property="v_address"
                label="安装地址"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" width="90" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click="deleteRow(scope.row.v_fault_id)"
                    type="primary"
                    size="mini"
                  >
                    回看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="fault">
          <div class="tips">
            <div class="round" style="background: orange">
              <div class="text">
                <div>不文明</div>
                <div>行为</div>
              </div>
            </div>
          </div>
          <div class="list">
            <el-table
              ref="singleTable"
              :data="elevatorHlsInfoUncivilized"
              highlight-current-row
              style="width: 100%"
              height="250"
            >
              <el-table-column
                property="dt_report_time"
                label="上报日期"
                align="center"
              >
              </el-table-column>
              <el-table-column
                property="v_fault_name"
                label="不文明行为类型"
                align="center"
              >
              </el-table-column>
              <el-table-column
                property="v_address"
                label="安装地址"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" width="90" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="deleteRow(scope.row.v_fault_id)"
                    type="primary"
                    size="mini"
                  >
                    回看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="vallage-list">
        <div class="nav">
          <div class="state">
            <div
              @click="changeState(0)"
              :class="stateType == '0' ? 'active type' : 'type'"
            >
              小区模式
            </div>
            <div
              @click="changeState(1)"
              :class="stateType == '1' ? 'active type' : 'type'"
            >
              地图模式
            </div>
            <div
              v-if="stateType == '2'"
              :class="stateType == '2' ? 'active type' : 'type'"
            >
              {{ villageName }}
            </div>
          </div>
          <div class="search">
            <div class="time-select">
              <p
                @click="changeTime('00')"
                class="time week"
                :class="timeType == '00' ? 'active' : ''"
              >
                周
              </p>
              <p
                @click="changeTime('11')"
                class="time mouth"
                :class="timeType == '11' ? 'active' : ''"
              >
                月
              </p>
              <p
                @click="changeTime('22')"
                class="time year"
                :class="timeType == '22' ? 'active' : ''"
              >
                年
              </p>
            </div>
          </div>
        </div>
        <!-- 小区模式 -->
        <div v-if="stateType == 0" class="vallage-content">
          <div
            class="item"
            v-for="(item, index) in vallageList"
            :key="index"
            @click="changeVillage(item.v_village_id, item.v_village_name)"
          >
            <div class="item-title">
              <div class="vallage-name">{{ item.v_village_name }}</div>
              <div class="ele-num">{{ item.elevatorNum }}</div>
            </div>
            <div class="ele-state">
              <div class="state">
                <div class="state-num">{{ item.peopleTrappedCount }}</div>
                <div class="state-type">
                  困人
                  <Icon type="md-man" style="color: #d1217f; font-size: 16px" />
                </div>
              </div>
              <div class="state">
                <div class="state-num">{{ item.repairOrder }}</div>
                <div class="state-type">
                  急修
                  <Icon
                    type="ios-construct"
                    style="color: #ef4444; font-size: 16px"
                  />
                </div>
              </div>
              <div class="state">
                <div class="state-num">{{ item.electroMobileCount }}</div>
                <div class="state-type">
                  电瓶车入梯
                  <Icon
                    type="ios-close-circle"
                    style="color: #ffc412; font-size: 16px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="vallageList.length == 0">暂无小区</div>
        </div>
        <!-- 地图模式 -->
        <div v-if="stateType == 1" class="map">
          <Baidumap ref="Baidumap" />
        </div>
        <!-- 电梯列表 -->
        <div v-if="stateType == 2" class="elevator-content">
          <div class="ele-state">
            <div class="item">
              <p style="background: #22c55e"></p>
              <div class="color">正常</div>
            </div>
            <div class="item">
              <p style="background: #64748b"></p>
              <div>离线</div>
            </div>
            <div class="item">
              <p style="background: #d1217f"></p>
              <div class="color">困人</div>
            </div>
            <div class="item">
              <p style="background: #ffc412"></p>
              <div>检修</div>
            </div>
            <div class="item">
              <p style="background: #ef4444"></p>
              <div>故障</div>
            </div>
          </div>
          <div class="elevator-list">
            <div
              class="item"
              v-for="(item, index) in elevatorList"
              :key="index"
              @click="
                () => {
                  $router.push({
                    path: '/monitor/single',
                    query: {
                      villageId: item.villageId,
                      registerNum: item.register_number,
                      elevatorId: item.v_elevator_id,
                      elevatorCode: item.v_elevator_code,
                    },
                  })
                }
              "
            >
              <!-- <div class="title">正常</div> -->
              <!-- 离线 -->
              <div v-if="item.i_on_line == 0">
                <!-- <div class="title">离线</div> -->
                <img src="../../assets/off-line.png" alt="" />
              </div>
              <!-- 困人 -->
              <div v-else-if="item.i_fault_status == 2">
                <!-- <div class="title">困人</div> -->
                <img src="../../assets/Trapped.png" alt="" />
              </div>
              <!-- 检修 -->
              <div v-else-if="item.i_mode_status != 0">
                <!-- <div class="title">检修</div> -->
                <img src="../../assets/overhaul.png" alt="" />
              </div>
              <!-- 故障 -->
              <div v-else-if="item.i_fault_status == 1">
                <!-- <div class="title">故障</div> -->
                <img src="../../assets/sos.png" alt="" />
              </div>
              <!-- 正常 -->
              <div v-else>
                <!-- <div class="title">正常</div> -->
                <img src="../../assets/normal.png" alt="" />
              </div>
              <div style="font-size: 14px">{{ item.v_elevator_name }}</div>
              <div style="font-size: 12px">{{ item.v_elevator_code }}</div>
            </div>
            <div v-if="elevatorList.length == 0">暂无电梯</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-right">
      <div class="num">
        <div class="flex ele-num">
          <div>
            <div class="nor-text" style="font-size: 14px">物联网电梯总数</div>
            <div class="nor-text2" style="font-size: 30px; font-weight: 700">
              {{ elevatorCount.total }}
            </div>
          </div>
        </div>
        <div class="flex ele-num">
          <div>
            <div class="nor-text" style="font-size: 14px">住宅电梯总数</div>
            <div
              class="highlight-text"
              style="font-size: 30px; font-weight: 700"
            >
              {{
                user.user.v_project_id == 8089373891892543488
                  ? '535'
                  : elevatorCount.total
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="state">
        <div class="title">电梯状态</div>
        <div class="main-state">
          <div
            v-for="(item, index) in elevatorState"
            :key="index"
            :style="{ color: item.color }"
          >
            <el-row>
              <el-col :span="11">
                <div class="grid-content bg-purple state-flex">
                  <div class="font-s">{{ item.name }}</div>
                  <div class="weight">{{ item.count }}</div>
                </div>
              </el-col>
              <el-col :span="13">
                <div
                  class="grid-content bg-purple state-flex"
                  style="justify-content: space-between; padding-right: 10px"
                >
                  <div class="font-s">{{ item.ratename }}</div>
                  <div>
                    <el-progress
                      :width="42"
                      :stroke-width="6"
                      type="circle"
                      :percentage="Number(item.rate)"
                      :format="perFormat"
                    ></el-progress>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="title">维保信息</div>
        <div class="main-info">
          <div class="contemt">
            <div v-for="(item, index) in intellData" :key="index">
              <p>{{ index + 1 }}</p>
              <span style="font-weight: 700; font-size: 16px"
                >{{ item.lable }}：{{ item.number }}</span
              >
            </div>
            <div v-if="intellData.length == 0">暂无信息</div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      footer-hide
      :mask="true"
      :mask-closable="false"
      :scrollable="true"
      v-model="modaDetail"
      width="550"
      class="model"
    >
      <div style="background: #fff">
        <Row :gutter="10">
          <Tabs size="small">
            <TabPane label="视频" class="TimeDiv">
              <video
                :src="videoUrl"
                controls
                ref="video"
                v-if="videoUrl != '' && videoUrl != null"
              >
                您的浏览器不支持video元素。
              </video>
              <br />
              <font style="margin-left: 205px" v-if="!videoUrl">暂无视频</font>
            </TabPane>
            <TabPane label="图片">
              <Scroll v-if="faultImageList.length !== 0">
                <div
                  :key="index"
                  dis-hover
                  style="margin: 32px 0"
                  v-for="(item, index) in faultImageList"
                >
                  <img :src="item" style="max-width: 100%" />
                </div>
              </Scroll>
              <br />
              <font
                style="margin-left: 210px"
                v-if="faultImageList.length === 0"
                >暂无图片</font
              >
            </TabPane>
          </Tabs>
        </Row>
      </div>
    </Modal>
  </div>
</template>
<script>
import Baidumap from '../map/map.vue'
import Moment from 'moment'
import 'moment/locale/zh-cn'
import {
  getElevatorHlsInfo,
  searchFaultList,
  countElevator,
  getFaultDetail,
  intelligentSupervision,
  villageCount,
  getElevatorsStatus,
  villagesCountRate,
  personShuttingLately,
  searchUncivilizedltList,
  searchMaiXinFaultList,
} from '@/api/home/index.js'
export default {
  data() {
    return {
      elevatorList: [],
      vallageList: [],
      elevatorHlsInfoFault: [],
      elevatorHlsInfoUncivilized: [],
      input2: '',
      stateType: 1,
      timeType: '00',
      select: 1,
      input: '',
      center: { lng: 0, lat: 0 },
      zoom: 3,
      elevatorCount: '',
      classificationParams: {
        register_number: '',
        villageId: '',
        timeFlag: '',
        faultType: '',
        backType: 1,
        vElevatorCode: '',
        videoUrl: '',
        vProjectId: '8089373891892543488',
      },
      intelligentData: {
        register_number: '',
        villageId: '',
        timeFlag: '00',
        faultType: '',
        vProjectId: '8089373891892543488',
      },
      listQuery: {
        iUncivilizedBehaviorFlag: 1,
        villageId: '',
        buildingId: '',
        vElevatorCode: '',
        dtReportTime: this.formatDate(-30) + '',
        dtEndTime: this.formatDate(0) + ' 23:59:59',
        iFaultType: '',
        overdue: '',
        iStatus: '',
        pageIndex: 1,
        pageSize: 10,
        vProjectId: '8089373891892543488',
      },
      rescuelistQuery: {
        villageId: '',
        buildingId: '',
        vElevatorCode: '',
        dtReportTime: this.formatDate(-30) + '',
        dtEndTime: this.formatDate(0) + ' 23:59:59',
        overdue: '',
        iStatus: 6,
        pageIndex: 1,
        pageSize: 10,
        vProjectId: '8089373891892543488',
      },
      rescuelistQuery2: {
        villageId: '',
        buildingId: '',
        vElevatorCode: '',
        dtReportTime: this.formatDate(-30) + '',
        dtEndTime: this.formatDate(0) + ' 23:59:59',
        overdue: '',
        iStatus: 5,
        pageIndex: 1,
        pageSize: 10,
        vProjectId: '8089373891892543488',
      },
      villageCountData: {
        overdue: '',
        pageIndex: '',
        pageSize: '',
        eType: '',
        timeFlag: '00',
        vProjectId: '8089373891892543488',
      },
      videoUrl: '',
      modaDetail: false,
      rescueInfo: null,
      HistoryRecordData: {
        vElevatorCode: '',
        timeFlag: '11',
      },
      SupervisionData: {
        iModeStatus: 2,
        vProjectId: '',
      },
      Supervision: '',
      intellData: [
        {
          lable: '当日困人电梯',
          number: '',
        },
        {
          lable: '当日急修电梯',
          number: '',
        },
        {
          lable: '昨日维保电梯数量(台)',
          number: '',
        },
        {
          lable: '本月需年检电梯数量(台)',
          number: '',
        },
        {
          lable: '逾期维保电梯',
          number: '',
        },
        {
          lable: '年检逾期电梯',
          number: '',
        },
      ],
      villageName: '',
      elevatorData: { villageId: '', vProjectId: '' },
      elevatorState: [
        {
          name: '电梯困人数',
          ratename: '电梯困人率',
          count: '',
          rate: '',
          color: '#d1217f',
          unit: '‰',
        },
        {
          name: '按期保养数',
          ratename: '按期保养率',
          count: '',
          rate: '',
          color: '#007be5',
          unit: '%',
        },
        {
          name: '故障急修数',
          ratename: '故障急修率',
          count: '',
          rate: '',
          color: '#ef4444',
          unit: '‰',
        },
        {
          name: '电瓶车入梯',
          ratename: '电瓶车入梯率',
          count: '',
          rate: '',
          color: '#f59e0b',
          unit: '%',
        },
      ],
      faultImageList: [],

      autoRefeshTimer: null,
    }
  },
  created() {
    this.initializePage()
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  components: {
    Baidumap,
  },
  mounted() {
    if (this.autoRefeshTimer) {
      clearInterval(this.autoRefeshTimer)
      this.autoRefeshTimer = null
    }
    this.autoRefeshTimer = setInterval(() => {
      this.initializePage()
    }, 600000)
  },
  destroyed() {
    if (this.autoRefeshTimer) {
      clearInterval(this.autoRefeshTimer)
      this.autoRefeshTimer = null
    }
  },
  methods: {
    initializePage() {
      this.getElevatorHlsInfo()
      this.searchFaultList()
      this.countElevator()
      this.Emergencyrescue()
      this.intelligentSupervision()
      this.villageCount()
      this.villagesCountRate()
    },
    buildParams(params) {
      params.vProjectId = this.user.user.v_project_id
      return params
    },
    changeState(state) {
      this.stateType = state
    },
    changeTime(state) {
      this.timeType = state
      this.villageCountData.timeFlag = state
      if (this.stateType == 1) {
        this.$refs.Baidumap.searchVillageMap(state)
      } else {
        this.villageCount()
      }
      this.villagesCountRate()
    },
    deleteRow(faultId) {
      this.modaDetail = true
      this.getFaultDetail(faultId)
    },
    //故障详情
    getFaultDetail(faultId) {
      getFaultDetail(faultId).then((res) => {
        if (res.code === '0000') {
          this.videoUrl = res.info.videoUrl
          this.faultImageList = res.info.imageList
        }
      })
    },

    // 紧急救援
    Emergencyrescue() {
      personShuttingLately(this.buildParams(this.classificationParams)).then(
        (res) => {
          this.rescueInfo = res.info
        }
      )
    },
    // 故障提示
    getElevatorHlsInfo() {
      // 根据wuyePlatform判度
      // searchFaultList(this.buildParams(this.rescuelistQuery)).then(res => {
      //     this.elevatorHlsInfoFault = res.info.list
      //     this.elevatorHlsInfoFault.forEach(item => {
      //         item.dt_report_time = item.dt_report_time
      //     })
      // })
      console.log(
        Moment('2023-05-17T22:22:21').format('YYYY-MM-DD HH:mm:ss'),
        123123
      )
      const arr1 = searchFaultList(this.buildParams(this.rescuelistQuery))
      const arr2 = searchFaultList(this.buildParams(this.rescuelistQuery2))
      Promise.all([arr1, arr2]).then((res) => {
        let arr = []
        arr = res[0].info.list.concat(res[1].info.list)
        arr.forEach((item) => {
          item.dt_report_time = Moment(item.dt_report_time).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        })
        arr.sort(function (a, b) {
          return b.dt_report_time < a.dt_report_time ? -1 : 1
        })
        this.elevatorHlsInfoFault = arr
      })

      // searchMaiXinFaultList(this.buildParams(this.rescuelistQuery)).then(res => {
      //     this.elevatorHlsInfoFault = res.info.list
      //     this.elevatorHlsInfoFault.forEach(item => {
      //         item.dt_report_time = item.dt_report_time
      //     })
      // })
    },
    // 不文明行为
    searchFaultList() {
      searchUncivilizedltList(this.buildParams(this.listQuery)).then((res) => {
        this.elevatorHlsInfoUncivilized = res.info.list
        this.elevatorHlsInfoUncivilized.forEach((item) => {
          item.dt_report_time = item.dt_report_time
        })
      })
    },

    // 小区列表
    villageCount() {
      villageCount(this.buildParams(this.villageCountData)).then((res) => {
        this.vallageList = res.info
      })
    },

    changeVillage(id, name) {
      this.stateType = 2
      this.villageName = name
      this.getElevatorsStatus(id)
    },
    // 电梯列表

    getElevatorsStatus(id) {
      this.elevatorData.villageId = id
      this.elevatorList = []
      getElevatorsStatus(this.elevatorData).then((res) => {
        this.elevatorList = res.info
      })
    },

    // 电梯数量
    countElevator() {
      countElevator('undefined').then((res) => {
        this.elevatorCount = res.info
      })
    },
    // 电梯状态
    villagesCountRate() {
      villagesCountRate(this.buildParams(this.villageCountData)).then((res) => {
        this.elevatorState[0].count = res.info.peopleTrappedCount.count
        this.elevatorState[0].rate = (
          parseFloat(res.info.peopleTrappedCount.rate) * 10
        ).toFixed(2)

        this.elevatorState[1].count = res.info.totalMaintenanceCount.count
        this.elevatorState[1].rate =
          Math.floor((res.info.totalMaintenanceCount.rate - 0) * 10) / 10

        this.elevatorState[2].count =
          res.info.faultOrderByConfirmOrCompleted.count
        this.elevatorState[2].rate = (
          parseFloat(res.info.faultOrderByConfirmOrCompleted.rate) * 10
        ).toFixed(2)

        this.elevatorState[3].count = res.info.electroMobileFaultCount.count
        this.elevatorState[3].rate =
          Math.floor((res.info.electroMobileFaultCount.rate - 0) * 10) / 10

        // if (res.info) {
        //   res.info.forEach(item => {
        //     if (item.key == '困人数') {
        //       this.elevatorState[0].count = item.count
        //       this.elevatorState[0].rate = item.rate
        //     }
        //     if (item.key == '保养数') {
        //       this.elevatorState[1].count = item.count
        //       this.elevatorState[1].rate = item.rate
        //     }
        //     if (item.key == '急修数') {
        //       this.elevatorState[2].count = item.count
        //       this.elevatorState[2].rate = item.rate
        //     }
        //     if (item.key == '电瓶车入梯数') {
        //       this.elevatorState[3].count = item.count
        //       this.elevatorState[3].rate = item.rate
        //     }
        //   })
        // }
      })
    },
    // 维保信息
    intelligentSupervision() {
      intelligentSupervision(this.buildParams(this.intelligentData)).then(
        (res) => {
          this.intellData.forEach((item, index) => {
            res.info.forEach((k, j) => {
              if (this.intellData[index].lable == k.lable) {
                this.intellData[index].number = k.number
              }
            })
          })
        }
      )
    },

    // 时间转化
    rTime(date) {
      var json_date = new Date(date).toJSON()
      var a = new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
      return a
        .substring(a.indexOf('-') + 1)
        .substring(0, a.substring(a.indexOf('-') + 1).lastIndexOf(':'))
    },
    perFormat(percentage) {
      return percentage < 0.1 ? `${percentage}‰` : `${percentage}%`
    },
  },
  watch: {
    modaDetail: function (newObj) {
      if (!newObj) {
        this.videoUrl = ''
        this.faultImageList = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';

::v-deep input[type='text'],
input[type='number'] {
  border-radius: 8px;
  height: 38px;
  margin-top: 4px;
}

::v-deep .el-input {
  width: 150px;
  margin-right: 6px;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

.el-table ::v-deep th.el-table__cell > .cell {
  @include font_color('tableHeaderTextColor');
}

.el-table {
  ::v-deep .el-table__cell {
    padding: 8px 0;
    @include font_color('tableCellTextColor');
    @include background_color('tableCellBgColor');
  }
}

.el-table {
  ::v-deep .el-table__empty-block {
    @include background_color('tableEmptyBgColor');
  }
}

.el-table {
  ::v-deep th.el-table__cell.is-leaf {
    @include border_color(seperatorColor);
  }
}

.el-progress-circle {
  width: 40px;
  height: 40px;
  color: red;
}

.el-progress--circle ::v-deep .el-progress__text {
  @include font_color(homeStatusProgressTextColor);
}

.red {
  color: #d1217f;
}

.blue {
  color: #007be5;
}

.pink {
  color: #ef4444;
}

.weight {
  font-weight: 700;
  font-size: 20px;
}

.orange {
  color: #f59e0b;
}

.bg-white {
  background-color: #fff;
}

.font-s {
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  // color: #707070;
}

.state-flex {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding-left: 10px;
}

.flex {
  flex: 1;
}

.home {
  height: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  @include background_color('homeBgColor');
}

.home-left {
  flex: 10;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .sos {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #fff;
    padding-left: 18px;
    border: 1px solid #ddd;

    .text {
      color: red;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 1px;
      margin-right: 10px;
    }

    .scool {
      flex: 1;
      overflow: hidden;
    }

    ul {
      display: flex;
      flex-direction: row;

      li {
        cursor: pointer;
        background-color: red;
        color: #fff;
        font-weight: 700;
        padding: 0 10px;
        margin-right: 10px;
      }
    }
  }

  .table {
    flex: 2;
    display: flex;
    gap: 10px;
    overflow-y: auto;

    .fault {
      flex: 1;
      display: flex;
      @include background_color('faultBgColor');
      overflow: hidden;
      border: 1px solid #ddd;
      @include border_color(panelBorderColor);

      .tips {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .round {
          width: 68px;
          height: 68px;
          font-size: 14px;
          font-weight: 700;
          margin: 0 auto;
          text-align: center;
          background-color: red;
          border-radius: 68px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .list {
        flex: 6;
        overflow: hidden;
      }

      ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar {
        display: none;
      }
    }

    .uncivilized {
      flex: 1;
      background-color: #fff;
    }
  }

  .vallage-list {
    flex: 7;
    @include background_color(homeVillageListBgColor);
    border: 1px solid #ddd;
    @include border_color(panelBorderColor);
    overflow: hidden;

    .nav {
      height: 50px;
      @include background_color(homeNavBgColor);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .state {
        margin-top: 5px;
        margin-left: 5px;
        display: flex;
        height: 45px;
        line-height: 45px;

        .type {
          color: #fff;
          background-color: #2d8cf0;
          padding: 0 20px;
          cursor: pointer;
          border-radius: 5px 5px 0 0;
        }

        .active {
          background-color: #fff;
          color: #2d8cf0;
        }
      }

      .search {
        display: flex;
        padding-right: 10px;

        .time-select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #2d8cf0;
          border: 1px solid #fff;
          border-radius: 6px;
          color: #fff;

          .time {
            padding: 8px 16px;
            cursor: pointer;
          }

          .week {
            border-radius: 6px 0 0 6px;
          }

          .year {
            border-radius: 0 6px 6px 0;
          }

          .mouth {
            border: 1px solid #fff;
            border-top: none;
            border-bottom: none;
          }

          .active {
            background-color: #fff;
            color: #2d8cf0;
          }
        }
      }
    }

    .vallage-content {
      height: calc(100% - 50px);
      @include background_color(homeVillageContentBgColor);
      @include font_color(homeVillageContentTextColor);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      overflow: auto;

      .item {
        width: calc((100% - 30px) / 4);
        height: 120px;
        border: 2px solid #2d8cf0;
        // background-color: rgb(245, 243, 243);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;

        .item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            width: 6px;
            height: 6px;
            background-color: #aaa;
            border-radius: 6px;
          }

          .vallage-name {
            font-size: 20px;
            text-shadow: 5px 2px 6px #aaa;
            // background-color: rgb(233, 225, 225);
            margin: 0 50px 0 10px;
            font-weight: 700;
          }

          .ele-num {
            margin-right: 20px;
            color: #007be5;
            font-weight: 700;
            font-size: 20px;
          }
        }

        .ele-state {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .state {
            flex: 1;
            text-align: center;
          }

          .state-num {
            font-size: 16px;
            font-weight: 700;
          }

          .state-type {
            margin-top: 4px;
            padding: 2px;
            @include font_color(homeVillageContentTextColor);

            color: #000;
            font-size: 13px;
            font-weight: 700;
            // background-color: #edeef6;
          }
        }
      }

      .item:hover {
        // border: 3px solid #2d8cf0;
        background-color: #bddfc2;
      }
    }

    .elevator-content {
      height: calc(100% - 50px);
      padding: 10px;
      overflow: auto;

      .ele-state {
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          margin-right: 20px;

          p {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 4px;
          }
        }
      }

      .elevator-list {
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;

        .item {
          width: calc((100% - 90px) / 10);
          height: 120px;
          @include background_color(homeElevatorItemBgColor);
          @include font_color(homeElevatorItemTextColor);
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          font-size: 12px;

          img {
            width: 60px;
            height: 60px;
            margin: 6px 0;
          }
        }
      }
    }

    .map {
      height: calc(100% - 50px);
      padding: 10px;
      overflow: auto;
      @include background_color(homeMapBgColor);
    }

    .vallage-content::after {
      content: '';
      flex: auto;
    }

    .vallage-content::-webkit-scrollbar {
      display: none;
    }

    .elevator-list::after {
      content: '';
      flex: auto;
    }

    .elevator-content::-webkit-scrollbar {
      display: none;
    }
  }
}

.home-right {
  flex: 2;
  // background-color: red;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .num {
    flex: 1;
    display: flex;
    border: 1px solid #ddd;
    @include border_color(panelBorderColor);
    gap: 10px;
    @include background_color(homeOverviewBgColor);

    .ele-num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;

      .nor-text {
        @include font_color(homeOverviewTextColor);
      }

      .highlight-text {
        @include font_color(homeOverviewHighlightTextColor);
      }
    }
  }

  .state {
    flex: 4;

    .title {
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      font-size: 24px;
      color: #000;
      @include font_color(homeStatusTitleTextColor);
    }

    .main-state {
      border: 1px solid #ddd;
      @include border_color(panelBorderColor);
      // height: calc(100% - 50px);
      border-bottom: none;
    }
  }

  .info {
    flex: 5;
    display: flex;
    flex-direction: column;

    .title {
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      font-size: 24px;
      @include font_color(homeInfoTitleTextColor);
    }

    .main-info {
      border: 1px solid #ddd;
      @include border_color(panelBorderColor);
      height: calc(100% - 50px);
      @include background_color(homeInfoBgColor);
      @include font_color(homeInfoTextColor);
      display: flex;
      flex-direction: column;

      .contemt {
        flex: 1;
        padding: 10px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
          display: flex;
          align-items: center;

          p {
            background-color: #2d8cf0;
            color: #fff;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            text-align: center;
          }
        }
      }

      .peo-info {
        margin-top: 10px;
        height: 40px;
        padding-left: 20px;
        background-color: #fff;

        i {
          font-size: 18px;
          margin-right: 6px;
        }
      }
    }
  }
}

.el-row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.bg-purple-dark {
  background: #fff;
}

.bg-purple {
  @include background_color(homeBgPurpleBgColor);
  border-bottom: 1px solid #ddd;
  @include border_color(seperatorColor);
}

.bg-purple-light {
  background: #fff;
}

.grid-content {
  min-height: 70px;
}

.row-bg {
  // padding: 10px 0 0;
  background-color: #fff;
}

.svgClass {
  width: 30px;
  height: 30px;
  color: pink;
}

.svgClass:hover {
  color: #409eff;
}

.svg-icon_home_index {
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
  overflow: hidden;
}

video {
  width: 100%;
  height: 500px;
}

::v-deep video[data-v-087d42bb] {
  height: 500px;
}

.blink {
  display: inline-block;
  font-size: 24px;
  margin: 10px;
  background: linear-gradient(left, #f71605, #e0f513);
  background: -webkit-linear-gradient(left, #f71605, #e0f513);
  background: -o-linear-gradient(right, #f71605, #e0f513);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: scratchy 0.253s linear forwards infinite;
  /* 其它浏览器兼容性前缀 */
  -webkit-animation: scratchy 0.253s linear forwards infinite;
  -moz-animation: scratchy 0.253s linear forwards infinite;
  -ms-animation: scratchy 0.253s linear forwards infinite;
  -o-animation: scratchy 0.253s linear forwards infinite;
}

@keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

/* 添加兼容性前缀 */
@-webkit-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

@-moz-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

@-ms-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

@-o-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}
</style>